import React, {useState} from 'react';
import Snackbar from '../../Snackbar/Snackbar';
import useDevices from '../../../hooks/useDevices/useDevices';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import i18n from "../../../i18n";

export function getSnackbarContent(hasAudio: boolean, hasVideo: boolean, error?: Error) {
    let headline = '';
    let message = '';
    const urlParams = new URLSearchParams(window.location.search);
    const lng = urlParams.get('lng') || 'ar' ;
    switch (true) {
        // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
        // permission to only their camera, or only their microphone.
        case error?.message === 'CameraPermissionsDenied':
            headline = i18n.t('MEDIA_ERROR.CAMERA_PERMISSIONS_DENIED.HEADLINE',{lng:lng});
            message = i18n.t('MEDIA_ERROR.CAMERA_PERMISSIONS_DENIED.MESSAGE',{lng:lng});
            break;
        case error?.message === 'MicrophonePermissionsDenied':
            headline = i18n.t('MEDIA_ERROR.MICROPHONE_PERMISSIONS_DENIED.HEADLINE',{lng:lng});
            message = i18n.t('MEDIA_ERROR.MICROPHONE_PERMISSIONS_DENIED.MESSAGE',{lng:lng});
            break;

        // This error is emitted when the user or the user's system has denied permission to use the media devices
        case error?.name === 'NotAllowedError':
            headline = i18n.t('MEDIA_ERROR.NOT_ALLOWED_ERROR.HEADLINE',{lng:lng});

            if (error!.message === 'Permission denied by system') {
                // Chrome only
                message = i18n.t('MEDIA_ERROR.NOT_ALLOWED_ERROR.MESSAGE1',{lng:lng});
            } else {
                message = i18n.t('MEDIA_ERROR.NOT_ALLOWED_ERROR.MESSAGE2',{lng:lng});
            }

            break;

        // This error is emitted when input devices are not connected or disabled in the OS settings
        case error?.name === 'NotFoundError':
            headline = i18n.t('MEDIA_ERROR.NOT_FOUND_ERROR.HEADLINE',{lng:lng});
            message = i18n.t('MEDIA_ERROR.NOT_FOUND_ERROR.MESSAGE',{lng:lng});
            break;

        // Other getUserMedia errors are less likely to happen in this app. Here we will display
        // the system's error message directly to the user.
        case Boolean(error):
            headline = i18n.t('MEDIA_ERROR.ERROR.HEADLINE',{lng:lng});
            message = `${error!.name} ${error!.message}`;
            break;

        case !hasAudio && !hasVideo:
            headline = i18n.t('MEDIA_ERROR.NO_AUDIO_NO_VIDEO.HEADLINE',{lng:lng});
            message = i18n.t('MEDIA_ERROR.NO_AUDIO_NO_VIDEO.MESSAGE',{lng:lng});
            break;

        case !hasVideo:
            headline = i18n.t('MEDIA_ERROR.NO_VIDEO.HEADLINE',{lng:lng});
            message = i18n.t('MEDIA_ERROR.NO_VIDEO.MESSAGE',{lng:lng});
            break;

        case !hasAudio:
            headline = i18n.t('MEDIA_ERROR.NO_AUDIO.HEADLINE',{lng:lng});
            message = i18n.t('MEDIA_ERROR.NO_AUDIO.MESSAGE',{lng:lng});
    }

    return {
        headline,
        message,
    };
}

export default function MediaErrorSnackbar({error}: { error?: Error }) {
    const {hasAudioInputDevices, hasVideoInputDevices} = useDevices();

    const {isAcquiringLocalTracks} = useVideoContext();

    const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

    const isSnackbarOpen =
        !isSnackbarDismissed &&
        !isAcquiringLocalTracks &&
        (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

    const {headline, message} = getSnackbarContent(hasAudioInputDevices, hasVideoInputDevices, error);

    return (
        <Snackbar
            open={isSnackbarOpen}
            handleClose={() => setIsSnackbarDismissed(true)}
            headline={headline}
            message={message}
            variant="warning"
        />
    );
}
